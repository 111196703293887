import { gql } from "@apollo/client";

export const GET_SEARCH_RESULTS = gql`
  fragment AmenityFragment on AmenityCategory {
    name
    items {
      name
    }
  }
  fragment ListingResultFragment on ListingResult {
    listing {
      listingId
      name
      photos
      featuredImageUrl
      bathroomCount
      bedroomCount
      guestCount
      shortLocation
      latitude
      longitude
      location
      url
      description
      amenities(grouped: true) {
        ...AmenityFragment
      }
      reviews {
        reviewCount
        avgScore
      }
    }
    origin
    nightlyPrice
    discountPercentage
    totalPriceWithoutDiscount
    nightlyPriceWithoutDiscount
    totalPrice
    isPriceSyncing
    dates {
      display {
        checkIn
        checkOut
      }
      totalMatches
    }
  }

  fragment NextPrevParamsFragment on NextPrevResponse {
    params {
      searchType
      page {
        offset
        limit
      }
    }
  }

  query GetSearchResults($parameters: SearchQueryParams) {
    search(parameters: $parameters) {
      defaultResults {
        results {
          ...ListingResultFragment
        }
        total
      }
      expandedResults {
        total
        searchType
        meta {
          ... on ExpandedLocationMeta {
            radius
          }
          ... on ExpandedAvailabilityMeta {
            adjustedCheckin
            adjustedLos
            checkinShift
            losShift
          }
        }
        results {
          ...ListingResultFragment
        }
      }
      pagination {
        total
        page {
          offset
          limit
        }
        next {
          ...NextPrevParamsFragment
        }
        prev {
          ...NextPrevParamsFragment
        }
      }
      searchLogId
      searchTerm
      currencyCode
      alternateSearchParameters {
        dates {
          checkIn
          checkOut
        }
        locations {
          name
          placeId
        }
      }
    }
  }
`;

export const GET_SEARCH_TOTAL = gql`
  query GetSearchTotals($parameters: SearchQueryParams) {
    search(parameters: $parameters) {
      defaultResults {
        total
      }
      expandedResults {
        total
      }
    }
  }
`;

export const GET_ATTRACTIONS = gql`
  query GetAttractions($status: String!, $params: AttractionsParams!) {
    attractions {
      search(status: $status, params: $params) {
        page
        results {
          imageUrl
          description
          address
          name
        }
      }
    }
  }
`;
