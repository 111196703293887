"use client";

import React, { useEffect, useState, type PropsWithChildren } from "react";

import { env } from "@/env.mjs";

import { IUserInfo, userService } from "@/services/user-service";

export interface IUserState {
  user: IUserInfo;
  isFetching: boolean;
}

const initialUserState: IUserState = {
  isFetching: true,
  user: {
    countryId: 234,
    countryCode: "GB",
    currencyId: 1,
    currencyCode: "GBP",
    showReonboardingBanner: false,
    isAuthenticated: false,
    language: "en",
    name: "",
    email: "",
    photoUrl: "",
    roles: null,
  },
};

type IUserContext = [IUserState, React.Dispatch<React.SetStateAction<IUserState>>];

export const UserContext = React.createContext<IUserContext>(null as unknown as IUserContext);

export const useUserContext = () => {
  return React.useContext(UserContext);
};

export function UserProvider({ children }: PropsWithChildren<{}>) {
  const [userState, setUserState] = useState<IUserState>(initialUserState);

  useEffect(() => {
    userService
      .getUserInfo(env.NEXT_PUBLIC_BASE_URL)
      .then((user) => setUserState({ isFetching: false, user }))
      .catch((err) => {
        setUserState((state) => ({
          ...state,
          isFetching: false,
        }));
        console.error(err);
      });
  }, []);

  return <UserContext.Provider value={[userState, setUserState]}>{children}</UserContext.Provider>;
}
