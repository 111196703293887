import format from "date-fns/format";

export enum DateFormats {
  ISO = "yyyy-MM-dd",
  ISO_NO_DAY = "yyyy-MM",

  LEGACY = "dd/MM/yyyy",

  PRETTY = "d MMM, yyyy",
  PRETTY_ONLY_DAY = "d",
  PRETTY_NO_YEAR = "d MMM",

  MONTH = "MMMM",
  MONTH_SHORT = "MMM",
  MONTH_YEAR = "MMMM yyyy",
}

export function formatDate(date: Date, dateFormat: DateFormats) {
  if (!date) return null;
  return format(date, dateFormat);
}
