import React, { MouseEventHandler, useId } from "react";

import { extractProps } from "@plum/ui-system";
import { Picture, Text } from "@plum/ui-core";
import { GetSearchResults_search_defaultResults_results_dates } from "@/gql/types/GetSearchResults";

import * as styles from "./listing-card";
import { FormattedPriceLabel } from "./FormattedPriceLabel";
import { FormattedDatesLabel } from "./FormattedDatesLabel";

export type ListingCardProps = {
  imageAlt: string;
  imageSrc: string;

  url: string;
  dates: GetSearchResults_search_defaultResults_results_dates | null;
  nightlyPrice: number | null;
  discountPercentage: number | null;
  showDescription?: boolean;
  name: string;
  locations: [string, string] | [string];
  index?: number;
  guests: number;
  bedrooms: number;
  description?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  onError?: (imageSrc: string) => void;
};

export function ListingCardWithPrices(props: ListingCardProps) {
  const [variants, restProps] = extractProps(props);

  const { url, imageAlt, imageSrc, onClick, onError } = restProps;

  const s = styles.listingCard(variants);

  const id = useId();

  return (
    <article className={s.root()} aria-labelledby={id}>
      {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
      <a
        href={url}
        className={s.overlay()}
        aria-labelledby={id}
        target="_blank"
        onClick={onClick}
        data-track-id="facet-dateless-dated-result-card"
      />
      <div className={s.image()}>
        <div className="absolute top-4 right-4 text-right z-30">
          <FormattedDatesLabel dates={props.dates} />
          {props.discountPercentage && props.discountPercentage > 0 ? (
            <div className="inline-block">
              <div className="rounded-[9999px] bg-green-dark flex items-center h-[32px] px-3 mt-2">
                <Text _scale="b-tiny" _bold className="text-white">
                  {props.discountPercentage}% off
                </Text>
              </div>
            </div>
          ) : null}
        </div>

        <Picture
          alt={imageAlt}
          src={imageSrc}
          width={680}
          breakpoints={[360, 720]}
          layout={"constrained"}
          aspectRatio={!props.showDescription ? 5 / 3 : 6 / 5}
          cdn="imgix"
          priority={props.index === 0}
          onError={() => onError && onError(imageSrc)}
        />
      </div>
      <div className={s.content()}>
        <Text id={id} _scale="b-medium" _bold>
          {props.name}
        </Text>
        <Text _scale="b-small" className="text-gray-700">
          {props.locations.join(", ")}
        </Text>
        <ul className="flex gap-2">
          <li className="flex gap-1 items-center">
            <Text _scale="b-small">{props.guests} guests</Text>
          </li>
          <span>•</span>
          <li className="flex gap-1 align-center">
            <Text _scale="b-small">{props.bedrooms} bedrooms</Text>
          </li>
        </ul>
        <div className="flex my-4">
          <FormattedPriceLabel price={props.nightlyPrice} className="text-gray-900" />
        </div>
        {props.description ? (
          <Text _scale="b-small" className="text-gray-900 line-clamp-6">
            {props.description}
          </Text>
        ) : null}
      </div>
    </article>
  );
}
