import i18next from "i18next";

import resourcesToBackend from "i18next-resources-to-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import { initReactI18next } from "react-i18next/initReactI18next";

import { getOptions } from "./settings";

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(
    resourcesToBackend(
      (language: string, namespace: string) =>
        import(`/public/locales/${language}/${namespace}.json`)
    )
  )
  .init({
    // let detect the language on client side
    compatibilityJSON: "v3",
    ...getOptions(undefined),

    detection: {
      lookupCookie: "i18n.langtag",
      order: ["path", "htmlTag", "cookie", "header", "navigator"],
    },
  });

export { useTranslation, Trans } from "react-i18next";
