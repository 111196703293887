/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ClientType {
  landingpagesdestination = "landingpagesdestination",
  landingpagesfacet = "landingpagesfacet",
  searchpagemain = "searchpagemain",
  similardatesrail = "similardatesrail",
  undefined = "undefined",
}

export enum FlexiblePeriod {
  month = "month",
  twoWeeks = "twoWeeks",
  week = "week",
  weekend = "weekend",
}

export enum OrderBy {
  default = "default",
  priceAsc = "priceAsc",
  priceDesc = "priceDesc",
}

export enum SearchType {
  default = "default",
  expandedavailability = "expandedavailability",
  expandedlocation = "expandedlocation",
  unfilteredlocation = "unfilteredlocation",
}

export interface AttractionsParams {
  listingIds?: string | null;
  placeId?: string | null;
  page: number;
  pageSize?: number | null;
  categories?: string[] | null;
  updatedSince?: string | null;
}

export interface BoundingBoxParams {
  ne: LatLng;
  sw: LatLng;
}

export interface DatesParams {
  checkIn: string;
  checkOut: string;
  flex?: number | null;
}

export interface DestinationParams {
  placeId?: string | null;
  searchTerm?: string | null;
}

export interface FlexibleParams {
  period: FlexiblePeriod;
  months: (string | null)[];
}

export interface GuestParams {
  adults: number;
  infants?: number | null;
  children?: number | null;
  pets?: number | null;
}

export interface LatLng {
  lat: number;
  lng: number;
}

export interface LocationParams {
  destination?: DestinationParams | null;
  boundingBox?: BoundingBoxParams | null;
}

export interface MetaParams {
  userInfo?: UserInfoParams | null;
}

export interface PagedRequest {
  offset: number;
  limit?: number | null;
  orderBy?: OrderBy | null;
}

export interface PriceMinMaxParam {
  min?: number | null;
  max?: number | null;
}

export interface PriceParams {
  nightly: PriceMinMaxParam;
}

export interface SearchFilter {
  filterName?: string | null;
  value?: string | null;
}

export interface SearchQueryParams {
  searchType?: SearchType | null;
  calendarDates?: DatesParams | null;
  flexibleDates?: FlexibleParams | null;
  guests?: GuestParams | null;
  page?: PagedRequest | null;
  price?: PriceParams | null;
  location?: LocationParams | null;
  staticFilters?: StaticFilters | null;
  searchFilter?: (SearchFilter | null)[] | null;
  getFilters?: boolean | null;
  meta?: MetaParams | null;
  searchId?: string | null;
  includeSearchId?: boolean | null;
  orderBy?: OrderBy | null;
  clientType?: ClientType | null;
  landing?: string | null;
}

export interface StaticFilters {
  minBedrooms?: number | null;
  minBathrooms?: number | null;
  minBeds?: number | null;
  infantsAllowed?: boolean | null;
  childrenAllowed?: boolean | null;
  petsAllowed?: boolean | null;
  instantBook?: boolean | null;
  isExclusive?: boolean | null;
  freeCancellation?: boolean | null;
  stepFreeAccess?: boolean | null;
  maxSteps?: number | null;
  amenities?: (number | null)[] | null;
  occasions?: (number | null)[] | null;
  propertyTypes?: (number | null)[] | null;
}

export interface UserInfoParams {
  UID?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
