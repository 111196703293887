import { Text } from "@plum/ui-core";

type Props = {
  className?: string;
  children: React.ReactNode;
};

export const DatesPill = ({ children, className = "" }: Props) => {
  return (
    <div className={`rounded-[9999px] bg-white h-[32px] flex items-center ${className}`}>
      <Text _bold className="px-3 text-b-sm sm:text-b-xs">
        {children}
      </Text>
    </div>
  );
};
