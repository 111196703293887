export const defaultLanguage = "en";
export const defaultNamespace = "landingpages";

export const languages = [defaultLanguage];
export const namespaces = [defaultNamespace, "common", "modals"];

export function getOptions(language: string | undefined, ns?: Array<string> | string) {
  return {
    debug: false,

    supportedLngs: languages,
    fallbackLng: defaultLanguage,

    fallbackNS: defaultNamespace,
    defaultNS: defaultNamespace,

    lng: language,
    ns: ns ?? namespaces,
  };
}
