export interface IUserInfo {
  isAuthenticated: boolean;
  name: string;
  email: string;
  photoUrl: string;
  countryId: number;
  countryCode: string;
  currencyId: number;
  currencyCode: string;
  showReonboardingBanner: boolean;
  language: string;
  roles?: string | string[] | null;
}

/**
 * Returns promise for user info endpoint
 */
const getUserInfo = async (baseUrl = "/"): Promise<IUserInfo> => {
  const response = fetch(`${baseUrl}api/user/info`, {
    headers: {
      "cache-control": "no-cache",
      pragma: "no-cache",
    },
    credentials: "include",
    method: "GET",
  });
  return response.then((result) => result.json());
};

export const userService = {
  getUserInfo,
};
