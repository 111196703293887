import urljoin from "url-join";

import qs from "query-string";

import { env } from "@/env.mjs";

import { transformLegacyParams } from "@/services/search";

import { useAppContext } from "@/data/AppProvider";

export const getSearchUrlForFacet = (params?: Record<string, number | string>) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { canonical, searchParams } = useAppContext();

  return urljoin(
    env.NEXT_PUBLIC_BASE_URL,
    "search",
    `?${qs.stringify({
      ...transformLegacyParams({
        location: canonical?.name as string,
        placeId: canonical?.placeId as string,
        ...qs.parse(searchParams || ""),
      }),

      ...params,
    })}`
  );
};

// For any commented params, we will still apply these filters to the search query.
// Any filters in the testingParams array that are uncommented will be removed and not applied to search:

const testingParams = [
  // Villas:
  "PropertyTypes=9",
  "propertyTypes=9",
  // Cottages:
  "PropertyTypes=11",
  "propertyTypes=11",
  // Luxury:
  "Occasions=10",
  "occasions=10",
  // Coastal:
  // "Occasions=17",
  // "occasions=17",
  // Beach:
  // "Occasions=23",
  // "occasions=23",
  // Penthouses:
  // "PropertyTypes=6",
  // "propertyTypes=6",
  // Apartments:
  // "PropertyTypes=1",
  // "propertyTypes=1",
];

export const applyParamRestrictions = (
  queryString: string | null | undefined,
  facetSlug: string | null | undefined
) => {
  if (!queryString || !facetSlug) {
    return queryString;
  }

  const searchParams = new URLSearchParams(queryString);

  testingParams.forEach((param) => {
    const [key, value] = param.split("=");
    if (searchParams.has(key) && searchParams.get(key) === value) {
      searchParams.delete(key);
      searchParams.set("landing", facetSlug || "");
    }
  });
  return searchParams.toString();
};

/* https://stackoverflow.com/questions/4059147/check-if-a-variable-is-a-string-in-javascript */
export const isString = (x: unknown) => typeof x === "string" || x instanceof String;

export function makeCamelCase(str: string): string {
  return str
    .split(" ")
    .map((word, i) => (i === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1)))
    .join("");
}

export function transformTitleToValidSearchParam(title: string) {
  return title.replace(/ /g, "-").replace(/,/g, "--");
}

export const handleSearchRedirect = (e, listingId, searchDestination, searchUrl) => {
  if (document.cookie.includes("exp_facet_to_search=test")) {
    if (searchDestination?.placeId) {
      e.preventDefault();
      return window.open(
        `${env.NEXT_PUBLIC_BASE_URL}search?listingId=${listingId}&placeId=${
          searchDestination.placeId
        }&location=${transformTitleToValidSearchParam(searchDestination.title)}`
      );
    }
    if (searchUrl) {
      e.preventDefault();
      return window.open(`${searchUrl}&listingId=${listingId}`);
    }
  }
};
