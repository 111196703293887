import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";

import { env } from "@/env.mjs";

const httpLink = createHttpLink({
  uri: env.NEXT_PUBLIC_API_GQL_URL,
  credentials: "include",
});

const apolloClient = new ApolloClient({
  connectToDevTools: true,
  link: httpLink,
  cache: new InMemoryCache(),
  name: "landingpages-ssr",
  version: "0.1",
});

export default apolloClient;
