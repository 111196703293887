import React, { useState } from "react";

import { ListingCardWithPrices } from "./ListingCardWithPrices";

import { getFirstSentences } from "@/utils/data";
import { ListingWithPrice } from "@/services/search";
import { ListingCard } from "@plum/ui-core";
import { useAppContext } from "@/data/AppProvider";
import { handleSearchRedirect } from "@/utils/search";

interface IProductCardContainerProps {
  data?: ListingWithPrice;
  index?: number;
  shouldShowDescription?: boolean;
}

export const EXP_DATELESS_DATED_SEARCH = false;

export const ProductCardContainer = ({
  data,
  shouldShowDescription,
}: IProductCardContainerProps) => {
  const { searchDestination } = useAppContext();
  const [selectedImage, setSelectedImage] = useState<number | "featured">(
    data?.featuredImageUrl ? "featured" : 0
  );
  if (!data) {
    return null;
  }

  function handleImageError(imageUrl: string) {
    if (selectedImage === "featured") {
      setSelectedImage(0);
    } else {
      setSelectedImage(selectedImage + 1);
    }
    console.error("Error loading image: ", imageUrl);
  }

  const imageSrc = (
    selectedImage === "featured" ? data.featuredImageUrl : data.photos![selectedImage]
  ) as string;

  return EXP_DATELESS_DATED_SEARCH ? (
    <ListingCardWithPrices
      imageSrc={imageSrc}
      imageAlt={data.name || ""}
      name={data.name || ""}
      locations={[data.shortLocation!]}
      bedrooms={data.bedroomCount as number}
      guests={data.guestCount as number}
      url={data.url!}
      dates={data.dates}
      showDescription={shouldShowDescription}
      nightlyPrice={data.nightlyPrice}
      discountPercentage={data.discountPercentage}
      description={shouldShowDescription ? getFirstSentences(data.description!, 2) : undefined}
      onError={handleImageError}
    />
  ) : (
    <ListingCard
      _variant="landscape"
      imageSrc={imageSrc}
      imageAlt={data.name || ""}
      name={data.name || ""}
      locations={[data.shortLocation!]}
      bedrooms={data.bedroomCount as number}
      guests={data.guestCount as number}
      url={data.url!}
      description={shouldShowDescription ? getFirstSentences(data.description!, 2) : undefined}
      onError={handleImageError}
      onClick={(e) => handleSearchRedirect(e, data.listingId, searchDestination, undefined)}
    />
  );
};
