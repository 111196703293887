import { useUserContext } from "@/data/UserProvider";
import { formatPrice } from "@plum/plum-ui-next";
import { Text } from "@plum/ui-core";

export const FormattedPriceLabel = ({
  price,
  className,
}: {
  price: number | null;
  className?: string;
}) => {
  const [{ user }] = useUserContext();

  const defaultpriceFormatSettings = {
    locale: user.language,
    currency: user.currencyCode,
    minimumFractionDigits: 0,
  };

  if (price === null || price === 0) {
    return null;
  }

  return (
    <Text className={className} _scale="b-medium">
      <Text as="span" _bold>
        {formatPrice(price || 0, defaultpriceFormatSettings).toString()}
      </Text>
      <span> night</span>
    </Text>
  );
};
