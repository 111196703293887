import { tv } from "tailwind-variants";

export const listingCard = tv({
  slots: {
    root: ["relative", "max-w-full", "grid", "gap-4"],
    overlay: ["absolute", "inset-0", "peer", "z-20", "focus-ring"],
    image: [
      "relative",
      "rounded-xs",
      "overflow-hidden",
      "after:absolute",
      "after:inset-0",
      "after:z-10",
      "after:bg-black/20",
      "after:opacity-0",
      "after:transition",
      "after:duration-300",
      "after:ease-in-out",
      "peer-hover:after:opacity-100",
      "peer-focus-visible:after:opacity-100",
    ],
    content: [],
  },

  variants: {
    _variant: {
      portrait: {
        root: ["w-[15rem]"],
        image: ["aspect-[4/5]"],
      },
      landscape: {
        root: ["max-w-[60rem]"],
      },
      compact: {
        root: ["grid-cols-[120px_1fr]", "items-center"],
      },
    },
  },
});
