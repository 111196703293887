import { formatDate, DateFormats } from "@/utils/dates";
import { DatesPill } from "./DatesPill";
import { ListingWithPrice } from "@/services/search";
import { useEffect, useState } from "react";

export const FormattedDatesLabel = ({
  dates,
  className,
}: {
  dates: ListingWithPrice["dates"] | null;
  className?: string;
}) => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (dates === null) {
    return null;
  }

  const checkInDate = new Date(dates?.display?.checkIn + "T00:00:00");
  const checkOutDate = new Date(dates?.display?.checkOut + "T00:00:00");

  let formattedCheckin;
  let formattedCheckout;

  if (checkInDate?.getMonth() === checkOutDate?.getMonth()) {
    formattedCheckin = formatDate(checkInDate, DateFormats.PRETTY_ONLY_DAY);
    formattedCheckout = formatDate(checkOutDate, DateFormats.PRETTY_NO_YEAR);
  } else {
    formattedCheckin = formatDate(checkInDate, DateFormats.PRETTY_NO_YEAR);
    formattedCheckout = formatDate(checkOutDate, DateFormats.PRETTY_NO_YEAR);
  }
  return (
    <DatesPill className={className}>
      {isClient ? (
        <>
          {formattedCheckin} – {formattedCheckout}
        </>
      ) : null}
    </DatesPill>
  );
};
