import { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";

export const env = createEnv({
  client: {
    NEXT_PUBLIC_ENV: z.string(),

    NEXT_PUBLIC_FULL_URL: z.string().url(),
    NEXT_PUBLIC_BASE_URL: z.string(),
    NEXT_PUBLIC_CDN_URL: z.string().url(),

    NEXT_PUBLIC_GTM_ID: z.string(),

    NEXT_PUBLIC_GTM_ENV: z.string(),
    NEXT_PUBLIC_GTM_AUTH: z.string(),
    NEXT_PUBLIC_GTM_PREVIEW: z.string(),

    NEXT_PUBLIC_FACEBOOK_APP_ID: z.string(),
    NEXT_PUBLIC_GOOGLE_APP_ID: z.string(),
    NEXT_PUBLIC_GOOGLE_MAPS_KEY: z.string(),

    NEXT_PUBLIC_TRACKJS_TOKEN: z.string(),
    NEXT_PUBLIC_TRACKJS_APPNAME: z.string(),
    NEXT_PUBLIC_PINGDOM_RUMID: z.string(),

    NEXT_PUBLIC_API_PATH: z.string().min(1),
    NEXT_PUBLIC_API_URL: z.string().url(),

    NEXT_PUBLIC_API_DESTINATIONS_URL: z.string().url(),
    NEXT_PUBLIC_API_PRICING_URL: z.string().url(),
    NEXT_PUBLIC_API_LISTINGS_URL: z.string().url(),
    NEXT_PUBLIC_API_GQL_URL: z.string().url(),
    NEXT_PUBLIC_API_COMMONDATA_URL: z.string().url(),
    NEXT_PUBLIC_API_SEARCH_URL: z.string().url(),
    NEXT_PUBLIC_API_SEO_URL: z.string().url(),
  },
  server: {
    FORCE_PREVIEW: z.string().optional(),

    CONTENTFUL_SPACE_ID: z.string().min(1),
    CONTENTFUL_ENVIRONMENT_ID: z.string().min(1),
    CONTENTFUL_DELIVERY_ACCESS_TOKEN: z.string().min(1),
    CONTENTFUL_DELIVERY_PREVIEW_ACCESS_TOKEN: z.string().min(1),
    BLOB_CONTAINER_NAME: z.string().min(1),
  },
  runtimeEnv: {
    NEXT_PUBLIC_ENV: process.env.NEXT_PUBLIC_ENV,

    NEXT_PUBLIC_FULL_URL: process.env.NEXT_PUBLIC_FULL_URL,
    NEXT_PUBLIC_BASE_URL: process.env.NEXT_PUBLIC_BASE_URL,
    NEXT_PUBLIC_CDN_URL: process.env.NEXT_PUBLIC_CDN_URL,

    NEXT_PUBLIC_GTM_ID: process.env.NEXT_PUBLIC_GTM_ID,
    NEXT_PUBLIC_GTM_ENV: process.env.NEXT_PUBLIC_GTM_ENV,
    NEXT_PUBLIC_GTM_AUTH: process.env.NEXT_PUBLIC_GTM_AUTH,
    NEXT_PUBLIC_GTM_PREVIEW: process.env.NEXT_PUBLIC_GTM_PREVIEW,

    NEXT_PUBLIC_FACEBOOK_APP_ID: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID,
    NEXT_PUBLIC_GOOGLE_APP_ID: process.env.NEXT_PUBLIC_GOOGLE_APP_ID,
    NEXT_PUBLIC_GOOGLE_MAPS_KEY: process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY,

    NEXT_PUBLIC_TRACKJS_TOKEN: process.env.NEXT_PUBLIC_TRACKJS_TOKEN,
    NEXT_PUBLIC_TRACKJS_APPNAME: process.env.NEXT_PUBLIC_TRACKJS_APPNAME,
    NEXT_PUBLIC_PINGDOM_RUMID: process.env.NEXT_PUBLIC_PINGDOM_RUMID,

    NEXT_PUBLIC_API_PATH: process.env.NEXT_PUBLIC_API_PATH,
    NEXT_PUBLIC_API_URL: process.env.NEXT_PUBLIC_API_URL,

    NEXT_PUBLIC_API_DESTINATIONS_URL: process.env.NEXT_PUBLIC_API_DESTINATIONS_URL,
    NEXT_PUBLIC_API_PRICING_URL: process.env.NEXT_PUBLIC_API_PRICING_URL,
    NEXT_PUBLIC_API_LISTINGS_URL: process.env.NEXT_PUBLIC_API_LISTINGS_URL,
    NEXT_PUBLIC_API_GQL_URL: process.env.NEXT_PUBLIC_API_GQL_URL,
    NEXT_PUBLIC_API_COMMONDATA_URL: process.env.NEXT_PUBLIC_API_COMMONDATA_URL,
    NEXT_PUBLIC_API_SEARCH_URL: process.env.NEXT_PUBLIC_API_SEARCH_URL,
    NEXT_PUBLIC_API_SEO_URL: process.env.NEXT_PUBLIC_API_SEO_URL,

    FORCE_PREVIEW: process.env.FORCE_PREVIEW,

    BLOB_CONTAINER_NAME: process.env.BLOB_CONTAINER_NAME,

    CONTENTFUL_SPACE_ID: process.env.CONTENTFUL_SPACE_ID,
    CONTENTFUL_ENVIRONMENT_ID: process.env.CONTENTFUL_ENVIRONMENT_ID,
    CONTENTFUL_DELIVERY_ACCESS_TOKEN: process.env.CONTENTFUL_DELIVERY_ACCESS_TOKEN,
    CONTENTFUL_DELIVERY_PREVIEW_ACCESS_TOKEN: process.env.CONTENTFUL_DELIVERY_PREVIEW_ACCESS_TOKEN,
  },
});
